import React from "react";
import { Link } from "react-router-dom";
import ChangeLanguaje from "./ChangeLanguaje";

import { useTranslation } from "react-i18next";
import "../i18next";

function HeaderMenu() {
  const { t } = useTranslation();
  return (
    <div className="header-wrap">
      {/* <div className="col-md-1">
        <div id="logo" className="logo">
          <a href="index.html" title="">
            <img
              src="./assets/images/logo.png"
              alt="logo"
              className="img-logo1"
            />
          </a>
        </div>
      </div> */}
      <div className="col-md-10 col-sm-10 col-12">
        <div className="nav-wrap">
          <nav id="mainnav" className="mainnav home1">
            <ul className="menu">
              <li>
                <a href="/"> {t("Navbar.1")}</a>
              </li>
              <li>
                <ChangeLanguaje />
              </li>
              <li>
                <a href="#C" title="">
                  {t("Navbar.2")}
                  <i className="fa fa-angle-down" aria-hidden="true"></i>
                </a>
                <ul className="sub-menu">
                  <li>
                    <Link to="/aboutus"> {t("Navbar.21")} </Link>
                  </li>
                  <li>
                    <a href="/whatsnews" title="">
                      {t("Navbar.5")}
                    </a>
                  </li>
                  <li>
                    <a href="/testimonials" title="">
                      {t("Navbar.22")}
                    </a>
                  </li>
                  <li>
                    <a href="/team" title="">
                      {t("Navbar.23")}
                    </a>
                  </li>
                </ul>
              </li>
              <li>
                <Link to="/farmer"> {t("Navbar.3")}</Link>
              </li>
              <li>
                <Link to="/offering_list/f=0"> {t("Navbar.4")}</Link>
              </li>

              <li>
                <a href="/contact_us" title="">
                  {t("Navbar.6")}
                </a>
              </li>

              <li>
                <a
                  href="https://admin.rwtc.mx/#/login"
                  target="_blank"
                  title=""
                >
                  {t("Navbar.7")}
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
      <div className="col-mt">
        <div className="header-right">
          <div className="list-icon">
            <div className="list-social">
              <a
                href="https://instagram.com/rwtc_mexico?utm_medium=copy_link"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: 5 }}
              >
                <img
                  alt="Instagram"
                  src="https://rwtc.mx/assets/icon/instagram-svgrepo-com.svg"
                  width="20"
                  height="20"
                />
              </a>
              <a
                href="https://web.facebook.com/roasterswarehousingtradingco"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: 5 }}
              >
                <img
                  alt="Facebook"
                  src="https://rwtc.mx/assets/icon/facebook-svgrepo-com.svg"
                  width="20"
                  height="20"
                />
              </a>
              <a
                href="https://www.linkedin.com/company/roasterswarehousingtradingco"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: 5 }}
              >
                <img
                  alt="linkedin"
                  src="https://rwtc.mx/assets/icon/linkedin-color-svgrepo-com.svg"
                  width="20"
                  height="20"
                />
              </a>
              <a
                href="https://wa.me/message/DFGA4EJTLAK5E1"
                target="_blank"
                rel="noopener noreferrer"
                style={{ margin: 5 }}
              >
                <img
                  alt="Whatsapp"
                  src="https://rwtc.mx/assets/icon/whatsapp-color-svgrepo-com.svg"
                  width="20"
                  height="20"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default HeaderMenu;
