import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import "../i18next";

import HeaderMenu from "../components/HeaderMenu";
import dataTestimonials from "../data/testimonials.json";

function Testimoniales() {
  const { t } = useTranslation();
  const [testimoniales, setTestitmoniales] = useState([]);

  useEffect(() => {
    setTestitmoniales(dataTestimonials.data);
  }, []);

  return (
    <div className="counter-scroll">
      <header id="headerTestimoniales">
        <div className="container-fluid">
          <div className="row">
            <div className="btn-menu">
              <span></span>
            </div>
            <HeaderMenu />
          </div>
        </div>
      </header>

      <section className="section4-testimonials">
        <div className="container">
          <div className="row">
            {testimoniales.map((item, index) => {
              return (
                <div key={item.id} className="col-md-6 col-sm-6">
                  <div
                    className={
                      item.Texto.length > 400
                        ? "testimonials-box2x wow fadeInUp"
                        : "testimonials-box2 wow fadeInUp"
                    }
                  >
                    <div className="slide-testi  ">
                      <img alt={`${item.Nombre}`} src={`${item.Image}`} />
                      <h3>{parse(item.Texto)}</h3>
                    </div>
                    <div className="designer-wrap">
                      <span className="title-designer-1"> {item.Nombre} </span>
                      <span className="title-designer-2">{item.De}</span>
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      </section>
    </div>
  );
}
export default Testimoniales;
