import React, { useState, useEffect } from "react";
import "react-responsive-modal/styles.css";
import "react-notifications/lib/notifications.css";
import {
  NotificationContainer,
  NotificationManager,
} from "react-notifications";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import "../i18next";
import HeaderMenu from "../components/HeaderMenu";

function ContactUS() {
  const { t } = useTranslation();
  const [state, setState] = useState({
    Nickname: "",
    Correo: "",
    Comentario: "",
  });

  const [errorformData, setErrorFormData] = useState({
    Nickname: "",
    Correo: "",
    Comentario: "",
  });

  const clearForm = () => {
    setState({
      Nickname: "",
      Correo: "",
      Comentario: "",
    });
  };

  const onSubmit = (e) => {
    e.preventDefault();
    // console.log("=>onSubmit", formData);
    setErrorFormData({
      Nickname: "",
      Correo: "",
      Comentario: "",
    });

    if (state.Nickname.length < 2) {
      setErrorFormData({
        ...errorformData,
        Nickname: "Name is required",
        Correo: "",
        Comentario: "",
      });
      return true;
    }

    if (state.Correo.length < 6) {
      setErrorFormData({
        ...errorformData,
        Nickname: "",
        Correo: "email is required",
        Comentario: "",
      });
      return true;
    }
    if (state.Comentario.length < 6) {
      setErrorFormData({
        ...errorformData,
        Nickname: "",
        Correo: "",
        Comentario: "Business is required",
      });
      return true;
    }

    console.log("=>Inicio envio de correo", state);

    // emailjs
    //   .sendForm(
    //     "service_pb53f4h",
    //     "template_ueluks9",
    //     formData,
    //     "user_iFoSKjKMJ5WHpJrT3YUK5"
    //   )
    //   .then(
    //     (result) => {
    //       console.log("=>correo enviado", result.text);
    //     },
    //     (error) => {
    //       console.log("=>Error envio de correo", error.text);
    //     }
    //   );

    var data = {
      service_id: "service_pb53f4h",
      template_id: "template_ueluks9",
      user_id: "user_iFoSKjKMJ5WHpJrT3YUK5",
      template_params: {
        Name: state.Nickname,
        reply_to: "nugasoft@gmail.com",
        Company: "",
        email: state.Correo,
        Finca: "",
        Business: state.Comentario,
        to_name: "Dietter",
      },
    };

    //"g-recaptcha-response": "03AHJ_ASjnLA214KSNKFJAK12sfKASfehbmfd...",

    var requestInfo = {
      method: "POST",
      body: JSON.stringify(data),
      headers: new Headers({
        "Content-Type": "application/json",
      }),
    };

    console.log("=>Send email: ", data);

    fetch("https://api.emailjs.com/api/v1.0/email/send", requestInfo)
      .then((response) => {
        console.log("=>response: ", response);
        return response.json();
      })
      .then(() => {
        console.log("=>Correo enviado");
      })
      .catch((err) => {
        console.log("=>Error: ", err);
      })
      .finally(() => {
        console.log("=>Fin del envio");
        NotificationManager.success(
          "Your message has been sent",
          "Send Success"
        );
        clearForm();
      });
  };

  return (
    <div className="counter-scroll">
      <header id="headerContactUs">
        <div className="container-fluid">
          <div className="row">
            <div className="btn-menu">
              <span></span>
            </div>
            <HeaderMenu />
          </div>
        </div>
      </header>
      <section className="section-map">
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <div className="google-map wow fadeInUp">
                <iframe
                  title="GoogleMap"
                  width="400"
                  height="450"
                  loading="lazy"
                  style={{ border: 0 }}
                  allowFullScreen
                  maptype="satellite"
                  src="https://www.google.com/maps/embed/v1/place?q=place_id:ChIJ_0lq853Z7IURGn7eZDdajf4&key=AIzaSyDXQFCzCIAhyI_M6-fLuN1-4Lcn5C9FgmI"
                ></iframe>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="section-contact">
        <div className="container">
          <div className="row">
            <div className="col-md-8 ">
              <div
                id="respond"
                className="comment-respond comment-style wow fadeInUp"
              >
                <h3 id="reply-title" className="comment-reply-title">
                  {parse(t("HomeContactUS.1"))}
                </h3>
                <span className="comment-respond-text">
                  {parse(t("HomeContactUS.2"))}
                </span>
                <span className="comment-respond-text2">*</span>
                <form
                  id="contactform"
                  className="contact-submit"
                  onSubmit={onSubmit}
                >
                  <div className="text-wrap clearfix">
                    <fieldset className="name-wrap">
                      <input
                        type="text"
                        id="name"
                        className="tb-my-input"
                        name="name"
                        tabIndex="1"
                        placeholder={parse(t("HomeContactUS.3"))}
                        value={state.Nickname}
                        size="32"
                        aria-required="true"
                        required
                        onChange={(email) =>
                          setState({
                            ...state,
                            Nickname: email.target.value,
                          })
                        }
                      />
                    </fieldset>
                    <fieldset className="email-wrap">
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        tabIndex="2"
                        value={state.Correo}
                        required
                        placeholder="name@example.com"
                        onChange={(email) =>
                          setState({
                            ...state,
                            Correo: email.target.value,
                          })
                        }
                      />
                    </fieldset>
                  </div>
                  <fieldset className="message">
                    <textarea
                      id="contact-message"
                      name="message"
                      rows="8"
                      tabIndex="3"
                      placeholder={parse(t("HomeContactUS.4"))}
                      aria-required="true"
                      value={state.Comentario}
                      required
                      onChange={(email) =>
                        setState({
                          ...state,
                          Comentario: email.target.value,
                        })
                      }
                    ></textarea>
                  </fieldset>
                  <p className="contact-submit">
                    <input
                      name="submit"
                      type="submit"
                      id="comment-reply"
                      className="submit"
                      value={parse(t("HomeContactUS.7"))}
                    />
                  </p>
                </form>
              </div>
            </div>
            <div className="col-md-4">
              <div className="box-detail wow fadeInUp">
                <div className="detail">
                  <div className="title-24 style-title24">
                    {parse(t("HomeContactUS.5"))}
                  </div>
                  :
                  <div
                    className="tf-text14 margin-top--2"
                    style={{ color: "white" }}
                  >
                    Calzada Emiliano Zapata 940-A, Col. Teran, Tuxtla Gtz.
                    Chiapas, México CP 29050
                  </div>
                </div>
                <div className="detail margin-bottom-106">
                  <div className="title-24 style-title25">Whatsapp</div>:
                  <div className="tf-text14 margin-top-5">
                    <a
                      href="https://wa.me/message/DFGA4EJTLAK5E1"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "white" }}
                    >
                      +52 961 140 2486
                    </a>
                  </div>
                </div>
                <div className="detail margin-bottom-106 ">
                  <div className="title-24 style-title26">
                    {parse(t("HomeContactUS.6"))}
                  </div>
                  :
                  <div className="tf-text14  text-style-14">
                    <a
                      href="mailto:operaciones@rwtc.mx?Subject=Info"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ color: "white" }}
                    >
                      operaciones@rwtc.mx
                    </a>
                  </div>
                </div>
                <div className="detail-2 ">
                  <div className="title-24 style-title27">
                    {parse(t("HomeContactUS.8"))}
                  </div>
                  :
                  <div className="tf-text14 icon-contact">
                    <a
                      href="https://instagram.com/rwtc_mexico?utm_medium=copy_link"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ margin: 5 }}
                    >
                      <img
                        alt="Instagram"
                        src="https://rwtc.mx/assets/icon/instagram-svgrepo-com.svg"
                        width="20"
                        height="20"
                      />
                    </a>
                    <a
                      href="https://web.facebook.com/roasterswarehousingtradingco"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ margin: 5 }}
                    >
                      <img
                        alt="Facebook"
                        src="https://rwtc.mx/assets/icon/facebook-svgrepo-com.svg"
                        width="20"
                        height="20"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/roasterswarehousingtradingco"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ margin: 5 }}
                    >
                      <img
                        alt="linkedin"
                        src="https://rwtc.mx/assets/icon/linkedin-color-svgrepo-com.svg"
                        width="20"
                        height="20"
                      />
                    </a>
                    <a
                      href="https://wa.me/message/DFGA4EJTLAK5E1"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ margin: 5 }}
                    >
                      <img
                        alt="Whatsapp"
                        src="https://rwtc.mx/assets/icon/whatsapp-color-svgrepo-com.svg"
                        width="20"
                        height="20"
                      />
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <NotificationContainer />
    </div>
  );
}
export default ContactUS;
