import React, { useEffect } from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";

import "./App.css";
import Index from "./pages/Index";
import Login from "./pages/auth/Login";
import OfferingList from "./pages/OfferingList";
import Farmer from "./pages/Farmer";
import AboutUs from "./pages/AboutUs";
import NoticePrivacy_es from "./pages/NoticePrivacy_es";
import NoticePrivacy_en from "./pages/NoticePrivacy_en";
import Team from "./pages/Team";
import ContactUS from "./pages/ContactUS";
import Testimoniales from "./pages/Testimoniales";
import Footer from "./components/Footer";

import { AuthProvider } from "./pages/AuthProvider";
import WhatsNews from "./pages/WhatsNews";
import WhatsNewsDetails from "./pages/WhatsNews_details";

import LangData from "./data/languajes.json";

function App() {
  useEffect(() => {
    fetch("https://ipapi.co/json/")
      .then((response) => response.json())
      .then((dataRS) => {
        console.log("=>resp: ", dataRS);
        var langTmp = [];

        if (dataRS.languages) {
          console.log("=>Opcion1: ", dataRS.languages);

          const itemTmp = dataRS.languages.split(",");
          console.log("=>Opcion1: ", itemTmp);
          console.log("=>Opcion1 tmp: ", itemTmp[0].toUpperCase());
          if (itemTmp.length > 0) {
            langTmp = LangData.data.filter(
              (item) =>
                item.TwoLetter.toUpperCase() === itemTmp[0].toUpperCase()
            );
          } else {
            console.log("=>Opcion2: ", dataRS.languages);
            const itemTmp = dataRS.languages.split(",");
            if (itemTmp.length > 0) {
              langTmp = LangData.data.filter(
                (item) =>
                  item.TwoLetter.toUpperCase() ===
                  dataRS.languages.toUpperCase()
              );
            }
          }

          if (langTmp.length > 0) {
            console.log("=>Opcion3: ", langTmp[0].Language);
            localStorage.setItem("language", langTmp[0].Language);
          } else {
            localStorage.setItem("language", "English");
          }
        }
      })
      .catch((error) => {});
  }, []);
  return (
    <AuthProvider>
      <Router>
        <Routes>
          <Route exact path="/" element={<Index />} />
          <Route path="/offering_list/:f" element={<OfferingList />} />
          <Route exact path="/farmer" element={<Farmer />} />
          <Route exact path="/aboutus" element={<AboutUs />} />
          <Route exact path="/team" element={<Team />} />
          <Route exact path="/contact_us" element={<ContactUS />} />
          <Route exact path="/testimonials" element={<Testimoniales />} />
          <Route exact path="/whatsnews" element={<WhatsNews />} />
          <Route
            exact
            path="/whatsnewsdetails/:id"
            element={<WhatsNewsDetails />}
          />
          <Route
            exact
            path="/aviso_privacidad"
            element={<NoticePrivacy_en />}
          />
          <Route exact path="/admin" element={<Login />} />
        </Routes>
        <Footer />
      </Router>
    </AuthProvider>
  );
}

export default App;
