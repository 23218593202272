import React from "react";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCoffee } from "@fortawesome/free-solid-svg-icons";

function Footer() {
  return (
    <footer id="footer">
      <div className="footer-widgets">
        <div className="container-fluid">
          <div className="row">
            <div className="col-md-3 col-sm-3">
              <div className="tf-footer">
                <ul className="">
                  <li className="logoft">
                    <img src="./assets/images/logo-footer.png" alt="" />
                  </li>

                  <li className="copy-right">
                    © 2021
                    <a href="https://rwtc.mx" className="center01">
                      {" "}
                      rwtc.mx
                    </a>{" "}
                    v1.0.0. All Rights Reserved.
                  </li>
                  <li className="icon-footer">
                    <a
                      href="https://instagram.com/rwtc_mexico?utm_medium=copy_link"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ margin: 5 }}
                    >
                      <img
                        alt="Instagram"
                        src="https://rwtc.mx/assets/icon/instagram-svgrepo-com.svg"
                        width="20"
                        height="20"
                      />
                    </a>
                    <a
                      href="https://web.facebook.com/roasterswarehousingtradingco"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ margin: 5 }}
                    >
                      <img
                        alt="Facebook"
                        src="https://rwtc.mx/assets/icon/facebook-svgrepo-com.svg"
                        width="20"
                        height="20"
                      />
                    </a>
                    <a
                      href="https://www.linkedin.com/company/roasterswarehousingtradingco"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ margin: 5 }}
                    >
                      <img
                        alt="linkedin"
                        src="https://rwtc.mx/assets/icon/linkedin-color-svgrepo-com.svg"
                        width="20"
                        height="20"
                      />
                    </a>
                    <a
                      href="https://wa.me/message/DFGA4EJTLAK5E1"
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ margin: 5 }}
                    >
                      <img
                        alt="Whatsapp"
                        src="https://rwtc.mx/assets/icon/whatsapp-color-svgrepo-com.svg"
                        width="20"
                        height="20"
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>

            <div className="col-md-2 col-sm-2">
              <div className="link-footer">
                <div className="list-link-1">
                  <h6 className="footer-tags">TOP LINKS</h6>
                  <ul>
                    <li>
                      <a href="/aboutus">About Us</a>
                    </li>
                    <li>
                      <a href="/farmer">Farmer</a>
                    </li>
                    <li>
                      <a href="/offering_list">Offering List</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-2">
              <div className="link-footer">
                <div className="list-link-2">
                  <h6 className="footer-tags">ABOUT US</h6>
                  <ul>
                    <li>
                      <a href="/aboutus">About Us</a>
                    </li>
                    <li>
                      <a href="/testimonials">Testimonials</a>
                    </li>
                    <li>
                      <a href="/team">Team</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="col-md-2 col-sm-2">
              <div className="link-footer">
                <div className="list-link-3">
                  <h6 className="footer-tags">OUR TERMS</h6>
                  <ul>
                    <li>
                      <a href="/aboutus">About Us</a>
                    </li>

                    <li>
                      <a href="/aviso_privacidad">Privacy Policy</a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
