import React, { useMemo, useState, useEffect } from "react";

import ClipLoader from "react-spinners/ClipLoader";
import _ from "lodash";
import CryptoJS from "crypto-js";
import { useTranslation } from "react-i18next";
import parse from "html-react-parser";
import "../i18next";
import HeaderMenu from "../components/HeaderMenu";
//import dataCategory from "../data/categorias.json";
import catPaises from "../data/paises.json";

import { removeDiacritics, Table } from "../utils/funciones";

// import { AuthContext } from "./AuthProvider";

function OfferingList() {
	const { t } = useTranslation();
	const columns = useMemo(() => [
		{
			Header: "Country",
			accessor: "Pais",
		},
		{
			Header: "STOCK (BAGS)",
			accessor: "STOCK_BAGS",
		},
		{
			Header: "PACKAGING",
			accessor: "PACKAGING",
		},
		{
			Header: "VARIETAL",
			accessor: "VARIETAL",
		},
		{
			Header: "PROCESS",
			accessor: "PROCESS",
		},
		{
			Header: "ORIGIN",
			accessor: "ORIGIN",
		},
		{
			Header: "ESTATE",
			accessor: "ESTATE",
		},

		{
			Header: "CUP PROFILE",
			accessor: "CUP_PROFILE",
		},
		{
			Header: "POSITION",
			accessor: "POSITION",
		},
	]);

	// const [state] = useContext(AuthContext);
	const [catProcess, setCatProcess] = useState({ Total: 0, data: [] });
	const [dataItems, setDataItems] = useState([]);
	const [dataItemsFilter, setDataItemsFilter] = useState([]);
	const [isLoading, setLoading] = useState(false);
	const [buscarData, setBuscarData] = useState("");
	const [selectCountry, setSelectCountry] = useState("");

	const [showModal, setShowModal] = useState(false);

	useEffect(() => {
		// setDataItems(dataShop.data);
		// setDataItemsFilter(dataShop.data);

		getProducts();
		getCatProcess();

		const dataCript = localStorage.getItem("tl_usr");

		if (dataCript) {
			const UserData = CryptoJS.AES.decrypt(
				dataCript,
				"RwTCmx2022$$$zI1NiJ9.eyJpc3MiOiJ"
			).toString(CryptoJS.enc.Utf8);
			//const UserData = JSON.parse(localStorage.getItem("tl_usr"));
			// console.log("=>UserData: ", JSON.parse(UserData));
		}
	}, []);

	// useEffect(() => {
	//   console.log("=>state: ", state);
	// }, [state]);

	useEffect(() => {
		// console.log("=>dataItemsFilter: ", dataItemsFilter);
		// console.log("=>buscar: ", buscarData);

		if (buscarData.length) {
			setLoading(true);
			const buscar = removeDiacritics(buscarData);

			//const searchText = buscar.toUpperCase();
			const text = removeDiacritics(buscar.toUpperCase());

			const filteredArray = dataItems.filter((item) => {
				const codeFullName = removeDiacritics(
					`${item.VARIETAL} ${item.ORIGIN} ${item.ESTATE} ${item.GRADE} ${item.PROCESS} ${item.PACKAGING_LBS} ${item.CUP_PROFILE} ${item.POSITION}`
				);
				if (codeFullName) {
					const itemSearch = codeFullName.toUpperCase();

					// console.log('=>itemSearch: ', item);
					// console.log('=>buscar: ', text);

					return itemSearch.search(text) !== -1;
				}
				return false;
			});

			setLoading(false);
			setDataItemsFilter(filteredArray);
		} else {
			setDataItemsFilter(dataItems);
		}
	}, [buscarData]);

	const getCatProcess = () => {
		setLoading(true);

		console.log("=>getCatProcess: ");

		const requestOptions = {
			method: "GET",
			headers: { "Content-Type": "application/json" },
		};
		fetch(
			`https://api.rwtc.mx/api/catalogo?option=categoriasProcess`,
			requestOptions
		)
			.then((response) => response.json())
			.then((dataRS) => {
				// console.log("=>getCatProcessRSXX:", dataRS);
				const result = _.sumBy(dataRS.items, "Total");

				// console.log("=>result: ", result);
				setCatProcess({ Total: result, data: dataRS.items });
			})
			.catch((error) => {
				console.error("=>Error CatProcess:", error);
			})
			.finally(() => {
				console.log("=>finally catProcess");
				setLoading(false);
			});
	};

	const FiltrarCafe = (item) => {
		if (item !== "all") {
			const newItems = dataItems.filter((d) => d.PROCESS === item.Etiqueta);
			console.log("=>Filtrar: ", item);
			setDataItemsFilter(newItems);
		} else {
			setDataItemsFilter(dataItems);
		}
	};

	const getProducts = () => {
		console.log("=>getProducts: ");
		setLoading(true);

		const requestOptions = {
			method: "POST",
			headers: { "Content-Type": "application/json" },
			body: JSON.stringify({
				page: 0,
				pageSize: 9999,
			}),
		};
		fetch("https://api.rwtc.mx/api/getShopCatalogo", requestOptions)
			.then((response) => response.json())
			.then((dataRS) => {
				console.log("=>getProducts dataRS:", dataRS);
				if (dataRS.success === true) {
					setDataItems(dataRS.data);
					setDataItemsFilter(dataRS.data);
				}
			})
			.catch((error) => {
				console.error("=>Error:", error);
			})
			.finally(() => {
				console.log("=>finally");
				setLoading(false);
			});
	};

	// const setBuscar = (text) => {
	//   console.log("=>Buscar: ", text);
	//   setBuscarData(text);
	// };

	const setSearchText = (e) => {
		setSelectCountry("");
		e.preventDefault();

		console.log("=>buscar: ", buscarData);
		const buscar = removeDiacritics(buscarData);
		setLoading(true);

		//const searchText = buscar.toUpperCase();
		const text = removeDiacritics(buscar.toUpperCase());

		const filteredArray = dataItems.filter((item) => {
			const codeFullName = removeDiacritics(
				`${item.VARIETAL} ${item.ORIGIN} ${item.ESTATE} ${item.GRADE} ${item.PROCESS} ${item.PACKAGING_LBS} ${item.CUP_PROFILE} ${item.POSITION}`
			);
			if (codeFullName) {
				const itemSearch = codeFullName.toUpperCase();

				// console.log('=>itemSearch: ', item);
				// console.log('=>buscar: ', text);

				return itemSearch.search(text) !== -1;
			}
			return false;
		});

		setLoading(false);
		setDataItemsFilter(filteredArray);
	};

	const btnSelectCountry = (Pais) => {
		console.log("=>buscar country: ", Pais);
		console.log("=>buscar dataItems: ", dataItems);

		setSelectCountry(Pais);
		setLoading(true);

		console.log("=>dataItems: ", dataItems);
		console.log("=>Pais: ", Pais);

		const filteredArray = dataItems.filter((item) => item.COUNTRY === Pais);

		console.log("=>filteredArray: ", filteredArray);

		setLoading(false);
		setDataItemsFilter(filteredArray);
	};

	const btnSelectProducto = (item) => {
		console.log("=>item: ", item);
		setShowModal(true);
	};

	return (
		<div className="counter-scroll">
			<header id="headerOfferingList">
				<div className="container-fluid">
					<div className="row">
						<div className="btn-menu">
							<span></span>
						</div>
						<HeaderMenu />
						<div className="wrap-banner">
							<div className="heading-title-page">
								{parse(t("HomeOfferingList.1"))}
							</div>
							<span className="heading-title-content">
								<span className="style-5">
									{parse(t("HomeOfferingList.2"))}
								</span>
							</span>
						</div>
					</div>
				</div>
			</header>

			<section className="main-content">
				<div className="container">
					<div className="row">
						<div className="col-md-8 col-sm-8">
							<div className="post-wrap">
								<article className="main-post ">
									<div className="img-box-blog wow fadeInUp">
										<img
											src="./assets/images/background/OfferingList.jpeg"
											alt=""
											width={60}
											className="img-blog-01"
										/>
										<p className="wrap-title-blog style-2">
											{parse(t("HomeOfferingList.3"))}
										</p>
									</div>
									<div className="heading-post wow fadeInUp">
										<h3 className="post-title style-2">
											{parse(t("HomeOfferingList.4"))}
										</h3>{" "}
										<p> {parse(t("HomeOfferingList.5"))}</p>
									</div>
								</article>
								<div className="team-box-container ">
									{catPaises.data.map((item) => {
										return (
											<img
												width={60}
												src={`https://rwtc.mx/assets/images/contenedores/${item.Imagen}`}
												alt={`${item.Name}`}
												className="img-contenedor"
												style={{ cursor: "pointer", margin: 1 }}
												onClick={(x) => btnSelectCountry(item.Name)}
											/>
										);
									})}
								</div>
							</div>
						</div>

						<div className="col-md-4 col-sm-4">
							<div className="sidebar">
								{/* <div className="widget widget_search wow fadeInUp">
                  <h3 className="widget-title">
                    {parse(t("HomeOfferingList.6"))}
                  </h3>
                  <form
                    action="#"
                    method="get"
                    role="search"
                    className="search-form style-1"
                    onSubmit={setSearchText}
                  >
                    <input
                      type="text"
                      value={buscarData}
                      onChange={(text) => setBuscarData(text.target.value)}
                    />
                    <a
                      className="search-submit"
                      href="#C"
                      onClick={setSearchText}
                    >
                      <i className="fa fa-search" aria-hidden="true"></i>
                    </a>
                  </form>
                </div> */}

								<aside className=" widget widget-categories wow fadeInUp">
									<h3 className="widget-title-01">
										{parse(t("HomeOfferingList.7"))}
									</h3>
									<ul>
										<li>
											<a href="#C" onClick={(x) => FiltrarCafe("all")}>
												{parse(t("HomeOfferingList.8"))}
											</a>
											<span className="blogsg-number">{catProcess.Total}</span>
										</li>
										{catProcess.data.map((item) => {
											return (
												<li>
													<a href="#C" onClick={(x) => FiltrarCafe(item)}>
														{item.Proceso}
													</a>
													<span className="blogsg-number"> {item.Total}</span>
												</li>
											);
										})}
									</ul>
								</aside>
							</div>
						</div>
					</div>
					<br />
					<br />
					<div className="row">
						{!isLoading && (
							<div style={{ textAlign: "center" }}>
								<div>
									<form
										action="#"
										method="get"
										role="search"
										className="search-form style-1"
										onSubmit={setSearchText}
									>
										<input
											type="text"
											placeholder="Buscar"
											value={buscarData}
											onChange={(text) => setBuscarData(text.target.value)}
										/>
									</form>
								</div>
								{parse(t("HomeFarmer.3"))} {dataItemsFilter.length}{" "}
								{parse(t("HomeFarmer.4"))} {dataItems.length}
								<Table
									columns={columns}
									data={dataItemsFilter}
									btnSelectProducto={btnSelectProducto}
								/>
							</div>
						)}

						{isLoading && (
							<ClipLoader color="#C0AC8E" loading={isLoading} size={150} />
						)}
					</div>
				</div>
			</section>
		</div>
	);
}
export default OfferingList;
